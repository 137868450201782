<!--
 * @Author: 张博洋
 * @Date: 2021-08-20 20:52:01
 * @LastEditTime: 2021-09-18 16:40:59
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/im/record.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="wrapper">
    <div style="overflow-y:auto; flex:1">
      <!-- <van-image :src="png"></van-image> -->
      <!-- <div class="p head">
        <h1 class="">医百·互联网医院</h1>
        <h2 class="mt8">处方笺</h2>
        <img src="../../static/img/line.png"
             alt="">
        <div class="con borderb mt24 line-height">
          <p>编号： <span>{{recordDetail.caseNo}}</span></p>
          <p>费别：<span>自费</span></p>
        </div>
        <div class="con mt12">
          <p>姓名： <span>{{recordDetail.patientName}}</span></p>
          <p>性别： <span>{{recordDetail.patientSex === 1 ? '男' : '女'}}</span></p>
          <p>年龄： <span>{{recordDetail.patientAge}}</span></p>
        </div>
        <div class="con mt12">
          <p>开方日期： <span>{{$.replyTime(recordDetail.createTime)}}</span></p>
        </div>
      </div>

      <div class="hsitory p mt8">
        <h1>诊断</h1>
        <div class="tags">
          <div class="tag"
               v-for="(d,index) in recordDetail.diseaseList"
               :key="index">{{d.v}}</div>
        </div>
      </div>

      <div class="p">
        <van-cell title="主诉"
                  :value="recordDetail.chiefComplained" />
        <van-cell title="现病史"
                  :value="recordDetail.presentHistory" />
        <van-cell title="既往病史"
                  :value="recordDetail.ownIllHistory" />
        <van-cell title="过敏史"
                  :value="recordDetail.allergyHistory" />
        <van-cell title="建议"
                  :value="recordDetail.suggest" />
        <van-cell title="其他"
                  :value="recordDetail.other" />
      </div>

      <div class="rp mt8 mb8"
           v-if="$route.name === 'im.prescription'">
        <h1>RP</h1>
        <div class="rp-item mb8"
             v-for="(item,index) in recordDetail.list"
             :key="index">
          <div class="head">
            <span class="name">{{item.drugName}}</span>
            <span class="num">x{{item.drugAmount}}</span>
          </div>
          <div class="con mt13">
            <span>规格：{{item.specification}} </span>
            <span>每次剂量：{{item.perDosage}} {{item.drugItem}}</span>
            <span>频次：{{item.frequency}}</span>
            <span>服药方法：{{item.drugUsage}}</span>
          </div>
        </div>
      </div>

      <div class="sign p">
        <div>
          医生：
        </div>
      </div> -->
    </div>

    <div class="btn-group mt8">
      <van-button class="download"
                  @click="download"
                  :loading="btnLoading"
                  round>下载PDF</van-button>

    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      pageName: '',
      recordDetail: Object.create(null),
      pdf: '',
      png: '',
    }
  },
  created () {
    console.log(this.$route)
    this.getPatientCase()

  },
  methods: {
    getPatientCase () {
      this.$axios({
        url: 'order/getPatientCase',
        data: {
          id: this.$route.query.id
        },
        elseData: {
          loading: true,
          ajaxLoading: true,
          errorTip: true,
        },
        _self: this,
      }).then(res => {
        console.log(res)
        this.recordDetail = res.d
        this.png = this.recordDetail.pdf.replace('pdf', 'png')
        console.log(this.png)
      })
    },
    download () {
      // this.$router.push({
      //   name: 'pdf.pdf',
      //   query: {
      //     src: this.recordDetail.pdf
      //   }
      // })
      wx.miniProgram.redirectTo({
        url: `/pages/protocol/protocol?src=${this.recordDetail.pdf}`
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/variables.scss';
.position {
  position: relative;
  z-index: 99;
}
.wrapper {
  display: flex;
  flex-direction: column;
}

.p {
  margin: 0 8px;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px 16px;
  .van-cell {
    padding-left: 0;
    padding-right: 0;
    .van-cell__title {
      color: #666;
      flex: none;
    }
    .van-cell__value {
      word-wrap: unset;
      color: #333;
      margin-left: 10px;
    }
  }
  .van-cell::after {
    left: 0;
    right: 0;
  }
}
.head {
  padding-top: 21px;
  h1 {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
  }
  h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }
  & > img {
    width: 100%;
  }
  .con {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      span {
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .borderb {
    border-bottom: 1px solid #f5f5f5;
  }
  .line-height {
    line-height: 48px;
  }
}
.hsitory {
  padding-bottom: 16px;
  h1 {
    height: 49px;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 49px;
  }
  .tags {
    display: flex;
  }
  .tag {
    background: #f5f5f5;
    border-radius: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 6px 13px;
    margin: 6px 14px 0 0;
    min-width: 70px;
    text-align: center;
    max-width: 150px;
    font-size: 14px;
    color: #333333;
  }
}
.rp {
  h1 {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 50px;
    padding: 0 20px;
  }
  .rp-item {
    background: #ffffff;
    border-radius: 8px;
    margin: 0 8px;
    padding: 20px 16px;
    .head {
      display: flex;
      justify-content: space-between;
      .name {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        display: inline-block;
      }
      .num {
        padding: 0 8px;
        line-height: 28px;
        border-radius: 4px;
        border: 1px solid #e5e5e5;
        display: inline-block;
        font-size: 16px;
        text-align: center;
        font-weight: 400;
        color: #333333;
      }
    }
    .con {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      span {
        width: 40%;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
      }
    }
  }
}
.sign {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  padding: 15px 16px;
}
.tip {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 21px;
  padding: 0 24px;
}

.btn-group {
  // position: fixed;
  // bottom: 0;
  width: 100%;
  display: flex;
  padding: 10px 16px 30px 16px;
  background: #ffffff;
  .download {
    width: 100%;
    flex: 1;
    background: color(primary) !important;
    color: #fff;
  }
}
</style>